import ReactQuill from "react-quill";
import styled from "styled-components";

export const Editor = styled(ReactQuill)`
  background: white;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  transition: all 0.2s ease-out 0s;
  margin: 5px 2px;
`;
export const Label = styled.label`
  font-size: 14px;
  margin: 10px 4px;
  font-weight: 700;
  color: #757575;
`;

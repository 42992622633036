import React, { useRef } from "react";
import CustomSelect from "../select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, CountryCode, DatetimeInput, FileContainer, Info, Input, InputContainer, Label, SubHead, TextArea } from "./styles";
import { ErrorMessage } from "../form/styles";
import { GetIcon, TickIcon, UploadIcon } from "../../../icons";
import Checkbox from "../checkbox";
import MultiSelect from "../multiSelect";
import EditorNew from "../editor";
import moment from "moment";
import InfoBoxItem from "./info";
import { projectSettings } from "../../project/brand/project";
import { Img } from "../list/styles";
import { food } from "../../../images";
import { useState } from "react";

function FormInput(props) {
  // Initialize translation function for current language
  const { t } = useTranslation();
  // Create a reference to file input element
  const fileInputRef = useRef(null);
  // Get theme colors from Redux store
  const themeColors = useSelector((state) => state.themeColors);
  const [country, setCountry] = useState(props.countries?.[0] ?? {});
  const [openCountry, setOpenCountry] = useState(false);
  try {
    switch (props.type) {
      // Render a regular text input
      case "text":
      case "password":
      case "email":
        return (
          <InputContainer className={`${props.dynamicClass ?? ""} ${projectSettings.formInputView}`} animation={props.animation}>
            <InfoBoxItem info={props.info} />
            {props.error?.length ? (
              <Label theme={themeColors} className={`${!props.value.toString().length > 0 ? "error shrink" : "error"}`}>
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            ) : (
              <Label theme={themeColors} className={`${!props.value.toString().length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            )}
            <Input {...(props.maxLength > 0 ? { maxLength: props.maxLength } : {})} disabled={props.disabled ?? false} autoComplete="on" theme={themeColors} className={`input ${props.value.toString().length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""} ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
          </InputContainer>
        );
      case "number":
        const value = isNaN(props.value) ? null : props.value;
        const handleKeyDown = (event) => {
          if (event.keyCode === 38 || event.keyCode === 40) {
            // Prevent the default behavior for up and down arrow keys
            console.log("event", "aborted");
            event.preventDefault();
          }
        };
        return (
          <InputContainer className={`${props.dynamicClass ?? ""} ${props.customClass ?? ""}`} animation={props.animation}>
            <InfoBoxItem info={props.info} />
            {props.error?.length ? (
              <Label theme={themeColors} className={`${!value?.toString().length > 0 ? "error shrink" : "error"}`}>
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            ) : (
              <Label theme={themeColors} className={`${!value?.toString().length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            )}
            <Input
              disabled={props.disabled ?? false}
              onKeyDown={handleKeyDown} // Attach the onKeyDown event handler
              onWheel={(e) => e.target.blur()}
              autoComplete="on"
              theme={themeColors}
              className={`input ${value?.toString().length > 0 ? "shrink" : ""}`}
              placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`}
              type={props.type}
              value={value}
              onChange={(event) => props.onChange(event, props.id, props.type, props.sub)}
              min={0}
              maxLength={props.maximum}
            />
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""}  ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
          </InputContainer>
        );
      case "mobilenumber":
        const value1 = isNaN(props.value) ? null : props.value;
        const handleKeyDown1 = (event) => {
          if (event.keyCode === 38 || event.keyCode === 40) {
            // Prevent the default behavior for up and down arrow keys
            console.log("event", "aborted");
            event.preventDefault();
          }
        };
        return (
          <InputContainer className={`${props.dynamicClass ?? ""} ${props.customClass ?? ""}`} animation={props.animation}>
            <InfoBoxItem info={props.info} />

            <CountryCode
              onClick={() => {
                setOpenCountry((prev) => !prev);
              }}
            >
              {`${country.flag} +${country.phoneCode}`}
              {openCountry && (
                <div className="options">
                  {props.countries.map((country, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 && <div className="line"></div>}
                        <div
                          className="option"
                          onClick={(e) => {
                            setOpenCountry((prev) => !prev);
                            setCountry(country);
                            props.onChange({ target: { value: value1 } }, props.id, props.type, props.sub, country);
                            e.stopPropagation();
                          }}
                        >{`${country.flag} +${country.phoneCode} - ${country.title}`}</div>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </CountryCode>
            <Input
              disabled={props.disabled ?? false}
              onKeyDown={handleKeyDown1} // Attach the onKeyDown event handler
              onWheel={(e) => e.target.blur()}
              autoComplete="on"
              theme={themeColors}
              className={`input phone${country.phoneCode.toString().length} ${value1?.toString().length > 0 ? "" : ""}`}
              placeholder={`WhatsApp Number${props.required ? " *" : ""}`}
              type="number"
              value={value1}
              onChange={(event) => props.onChange(event, props.id, props.type, props.sub, country)}
              min={0}
              max={Math.pow(10, country.PhoneNumberLength) - 1}
            />
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""}  ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
          </InputContainer>
        );
      // Render a time input with time picker
      case "time":
        let userFriendlyTime = typeof props.value === "undefined" || props.value === null ? null : props.value.length > 0 ? new Date(props.value) : null;
        return (
          <InputContainer className={`${props.dynamicClass ?? ""}`}>
            <InfoBoxItem info={props.info} />
            <DatetimeInput theme={themeColors} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" selected={userFriendlyTime} dateFormat="h:mm aa" className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholderText={`${t(props.label)}${props.required ? " *" : ""}`} type={props.type} onChange={(event) => props.onChange(event, props.id, props.type)} />
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              {t(props.label)}
            </Label>
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""}  ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
          </InputContainer>
        );
      // Render a date input with date picker
      case "date":
        let userFriendlyDate = typeof props.value === "undefined" || props.value === null ? null : props.value.length > 0 ? new Date(props.value) : null;
        //console.log("props.value", props.value, userFriendlyDate);
        return (
          <InputContainer className={`${props.dynamicClass ?? ""} ${props.customClass ?? ""}`}>
            <InfoBoxItem info={props.info} />
            <DatetimeInput showYearDropdown yearDropdownItemNumber={70} minDate={props.minDate ?? moment().toDate()} maxDate={props.maxDate ?? moment().add(1, "year").toDate()} dateFormat={"yyyy-MM-dd"} theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholderText={`${t(props.label)}${props.required ? " *" : ""}`} type={props.type} value={userFriendlyDate} selected={userFriendlyDate} onChange={(event) => props.onChange(event, props.id, props.type)} />
            {props.error?.length ? (
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            ) : (
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            )}
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""}  ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
          </InputContainer>
        );
      // Render a datetime input with date and time pickers
      case "datetime":
        let userFriendlyDateTime = props.value.length > 0 ? new Date(props.value) : null;

        return (
          <InputContainer className={`${props.dynamicClass ?? ""}`}>
            <InfoBoxItem info={props.info} />
            <DatetimeInput showYearDropdown yearDropdownItemNumber={70} minDate={props.minDate ?? moment().toDate()} maxDate={props.maxDate ?? moment().add(1, "year").toDate()} theme={themeColors} showTimeSelect timeIntervals={1} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholderText={`${t(props.label)}${props.required ? " *" : ""}`} type={props.type} value={userFriendlyDateTime} selected={userFriendlyDateTime} dateFormat={"yyyy-MM-dd hh:mm a"} onChange={(event) => props.onChange(event, props.id, props.type)} />
            {props.error?.length ? (
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            ) : (
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            )}
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""}  ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
          </InputContainer>
        );
      // Render a file and image
      case "image":
      case "file":
        function formatSize(sizeInBytes) {
          if (sizeInBytes < 1024) {
            return sizeInBytes + " bytes";
          } else if (sizeInBytes < 1024 * 1024) {
            return (sizeInBytes / 1024).toFixed(2) + " KB";
          } else {
            return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
          }
        }
        const size = formatSize(props.value[0] ? props.value[0].size : 0);
        const handleButtonClick = () => {
          fileInputRef.current.click();
        };
        const image = props.formValues["old_" + props.name];
        return (
          <FileContainer className={`${props.dynamicClass ?? ""}`} theme={themeColors}>
            {image && (
              <Img
                className="contain"
                onClick={(e) => {
                  // onClick({ src: e.target.src.replace("/thumbnail", "") });
                }}
                src={image ? process.env.REACT_APP_CDN + image : food}
              />
            )}
            <InfoBoxItem info={props.info} />
            <button onClick={handleButtonClick}>
              <UploadIcon />
              {t(props.type, { label: t(props.label) }) + (props.required ? " *" : "") + (props.value.length > 0 ? ` : ${props.value[0].name} (${size})` : "")}
            </button>
            <Input ref={fileInputRef} style={{ display: "none" }} theme={themeColors} accept={props.type === "image" ? `image/*` : ``} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={`file`} onChange={(event) => props.onChange(event, props.id, props.type)} />
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info image" : "image"} `} dangerouslySetInnerHTML={{ __html: props.error }} />}
          </FileContainer>
        );
      // Render a textarea
      case "textarea":
        return (
          <InputContainer className={`textarea ${props.dynamicClass ?? ""}`}>
            <InfoBoxItem info={props.info} />
            {props.error?.length ? (
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
                {props.error}
              </Label>
            ) : (
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
            )}
            <TextArea theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}  ${props.size ?? ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} value={props.value} onChange={(event) => props.onChange(event, props.id)} />
            {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info image" : "image"}`} dangerouslySetInnerHTML={{ __html: props.error }} />}
          </InputContainer>
        );
      case "htmleditor":
        return <EditorNew dynamicClass={`${props.dynamicClass ?? ""}`} key={props.id} type={props.type} placeholder={props.placeholder} value={props.value} id={props.id} onChange={props.onChange}></EditorNew>;
      // Render a submit button
      case "submit":
        return (
          <Button theme={themeColors} className="submit" disabled={props.disabled} type={props.type} onClick={props.onChange}>
            {props.value}
          </Button>
        );
      case "button":
        return (
          <Button theme={themeColors} className={props.customClass} disabled={props.disabled} type={props.type} onClick={props.onChange}>
            {props.icon ? <GetIcon icon={props.icon}></GetIcon> : null}
            <span> {props.value}</span>
          </Button>
        );
      // Render a close button
      case "close":
        return (
          <Button disabled={props.disabled ?? false} theme={themeColors} className={"close " + props.className} type={props.type} onClick={props.onChange}>
            {props.value}
          </Button>
        );
      // Render a cehckbox
      case "checkbox":
        return (
          <InputContainer className={`checkbox ${props.dynamicClass ?? ""} ${props.customClass ?? ""} `}>
            <InfoBoxItem info={props.info} />
            <Label className="checkbox">
              <Checkbox
                theme={themeColors}
                label={t(props.placeholder)}
                className={"checkbox " + props.customClass}
                type={props.type}
                checked={props.value}
                onChange={(event) => {
                  console.log(event.target.checked === false ? false : true);
                  props.onChange(event.target.checked === false ? false : true, props.id, props.type);
                }}
              ></Checkbox>
              {/* <span dangerouslySetInnerHTML={{ __html: t(props.placeholder) }}></span> */}
            </Label>
          </InputContainer>
        );
      // Render a select box
      case "select":
        return <CustomSelect theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></CustomSelect>;
      case "multiSelect":
        return <MultiSelect theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></MultiSelect>;
      case "info":
        return (
          <Info className={` ${props.dynamicClass}`}>
            <GetIcon icon={"info"}></GetIcon> <span dangerouslySetInnerHTML={{ __html: props.content }}></span>
          </Info>
        );
      case "html":
        return <Info className={` ${props.dynamicClass}`}>{props.content}</Info>;
      case "title":
        return (
          <SubHead theme={themeColors} className={`title ${props.dynamicClass}`}>
            <span>
              {props.icon ? <GetIcon icon={props.icon}></GetIcon> : null}
              {t(props.title ?? "")}
            </span>
          </SubHead>
        );
      case "hidden":
        return <Input disabled={props.disabled ?? false} autoComplete="on" theme={themeColors} className={`input ${props.value.toString().length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />;
      default:
        return <></>;
    }
  } catch (err) {
    console.log(props);
    return <></>;
  }
}
export default FormInput;

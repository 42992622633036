import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  &.vertical-menu {
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    min-height: 73vh;
  }
  &.horizontal {
    > .horizontal .tab {
      margin: 1em 1.5em 0px;
      max-height: 100%;
    }
    > .menu > div {
      box-shadow: none;
      border-radius: 12px;
      width: inherit !important;
      white-space: nowrap !important;
      &::after {
        bottom: 0;
        width: 100% !important;
        height: 2px !important;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 0;
    &.vertical-menu {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const TabHeader = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: auto;
  margin: 0px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: auto;
  margin: 0px;
  gap: 15px;
  padding: 10px 15px 0px 25px;
  border-right:1px solid #E2E4E9;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px; */
  &.vertical-menu {
    /* padding: 20px 0px 20px;
    margin: 0px 0px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px; */
    border-radius: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    min-width: 15em;
    
    height: 100%;
    max-width: 15em;
  }
  &.custom {
    margin: 0;
  }
  &.horizontal {
    padding: 10px 25px 0px 25px;
  }
  &.sub-menu {
    max-width: 6em;
    min-width: 6em;
    padding: 0;
  }
  @media (max-width: 768px) {
    margin: 0px 0px;
    &.vertical-menu {
      margin: 0px;
      flex-direction: row;
      flex-direction: row;
      height: 50px;
      max-width: 100%;
      padding: 0;
      gap: 15px;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 7px 0px;
      padding: 0px 10px 0 20px;
    }
  }
`;
export const TabContents = styled.div`
  flex: auto;
  width: 100%;
  margin-bottom: 30px;
  &.vertical-menu {
    display: flex;
    width: calc(100% - 240px);
    max-width: calc(100% - 240px);
    padding: 14px 20px 0 20px;
    /* position: absolute; */
    left: 260px;
    overflow: auto;
    margin-bottom: 0;
    bottom: 0;
    top: 0;
  }
  @media (max-width: 768px) {
    &.vertical-menu {
      width: calc(100%);
      max-width: calc(100%);
      padding: 0;
    }
  }
`;
export const Tab = styled.div`
  padding: 0px;
  /* border-top: 1px solid #d0d0d0; */
  display: none;
  /* box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px; */
  border-radius: 10px;
  flex-direction: column;
  &.custom {
    margin: 10px 0px 0px;
    padding: 0px;
  }
  &.vertical-menu {
    flex: auto;
  }
  &.horizontal {
    > .data-layout {
      padding: 1.65em 1.6em 0px !important;
    }
  }
  ${(props) =>
    props.active &&
    `
    display: flex;
  `}
  @media (max-width: 768px) {
    &.vertical-menu {
      flex: auto;
      width: 100%;
    }
  }
`;
export const PopMenuItem = styled.div`
  cursor: pointer;
  padding: 10px 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 12px 10px;
  gap: 10px;
  font-weight: normal !important;
  align-items: center;
  display: flex;
  color: #525866;
  svg {
    width: 20px;
  }
  :hover {
    color: black;
    background-color: #f6f8fa;
    font-weight: 500 !important;
    svg {
      color: ${(props) => props.theme.theme};
    }
  }
  &:first-child {
    border-radius: 10px;
  }
  &:last-child {
    border-radius: 10px;
  }
  &.active {
    background-color: #f6f8fa;
    color: black;
    font-weight: 500 !important;
    svg {
      color: ${(props) => props.theme.theme};
    }
    font-weight: bold;
    position: relative;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
export const PopIconMenuItem = styled.div`
  cursor: pointer;
  padding: 10px 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 12px 10px;
  gap: 10px;
  font-weight: normal !important;
  align-items: center;
  display: flex;
  color: #525866;
  flex-direction:column;
  font-size:12px;
  text-align:center;
  svg {
    width: 30px;
    font-size: 20px;
  }
  :hover {
    color: black;
    background-color: #f6f8fa;
    font-weight: 500 !important;
    svg {
      color: ${(props) => props.theme.theme};
    }
  }
  &:first-child {
    border-radius: 10px;
  }
  &:last-child {
    border-radius: 10px;
  }
  &.active {
    background-color: #f6f8fa;
    color: black;
    font-weight: 500 !important;
    svg {
      color: ${(props) => props.theme.theme};
    }
    font-weight: bold;
    position: relative;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
export const TabLink = styled.div`
  cursor: pointer;
  padding: 10px 0px;
  flex: 1 1 50%;
  background-color: rgb(255, 255, 255);
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  flex: inherit;
  gap: 10px;
  /* min-width: 60px;  */
  white-space: nowrap;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  transition: all 0.1s ease-in;

  :hover {
    /* transition: padding 0s ease-in; */
    /* font-weight: bold; */
    /* padding: 5px 13.1px; */
  }
  &:first-child {
    border-radius: 10px;
  }
  &:last-child {
    border-radius: 10px;
  }
  &.active {
    /* background-color: ${(props) => props.theme.pageForeground}; */
    background-color: #f6f8fa;
    color: ${(props) => props.theme.theme};
    font-weight: bold;
    position: relative;
    &.active::after {
      border: 0px solid rgb(129, 2, 129);
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      bottom: 0px;
      left: 0;
      background: rgb(129, 2, 129);
      transition: all 0.15s ease-in-out 0s;
      position: absolute;
      @media (max-width: 768px) {
        bottom: 1px;
        width: 100%;
        height: 2px;
        border-radius: 10px;
      }
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  && {
    .vertical-menu & {
      width: -webkit-fill-available;
      justify-content: left;
      text-align: left;
      box-shadow: none;
      padding: 8px 13px !important;
      white-space: pre-wrap;
      border-radius: 12px !important;
      margin-right: 10px;
      svg {
        min-width: 20px;
      }
      &.active::after {
        content: "";
        display: block;
        position: absolute;
        right: 15px;
        left: auto;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 6px;
        background-color: transparent;
        height: 6px;
        border-top: 1px solid black;
        border-right: 1px solid black;
        transition: all 0.15s ease-in-out 0s;
        @media (max-width: 768px) {
          width: 100%;
          height: 2px;
          margin-left: 0;
        }
      }
    }
  }
  && {
    .vertical-menu & {
      padding: 10px 0px;
      &.active {
        box-shadow: none;
      }
      border-radius: 0;
    }
  }
  @media (max-width: 768px) {
    white-space: nowrap;
    flex: none;
    && {
      .vertical-menu & {
        width: auto;
        height: 100%;
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import Loader from "../../core/loader";
import Message from "../../core/message";
import { NoData } from "../../core/list/styles";
import { getData } from "../../../backend/api";
import { useParams } from "react-router-dom";
import { changeThemeColor } from "../../../store/actions/theme";
import { useDispatch, useSelector } from "react-redux";
const withLayout = (WrappedComponent) => (url) => {
  return (props) => {
    const { id, slug } = useParams();
    const [message, setMessage] = useState({
      type: 1,
      content: "Message!",
      okay: "Start Over",
    });
    const themeColors = useSelector((state) => state.themeColors);
    const [showMessage, setShowMessage] = useState(false);
    const [showLoader, setShowLoader] = useState(true); // Initially show loader
    const [isWhitelisted, setIsWhitelisted] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [response, serResponse] = useState(null);
    const [user] = useState(JSON.parse(localStorage.getItem("--token")) ?? null);
    const dispatch = useDispatch();
    const setLoaderBox = (status) => {
      setShowLoader(status);
    };

    const setMessageBox = (messageContent) => {
      setMessage(messageContent);
      setShowMessage(true);
    };

    const closeMessage = () => {
      setMessage({ ...message, onClose: null });
      setShowMessage(false);
    };

    useEffect(() => {
      const fetchData = async () => {
        setIsLoaded(true);
        if (isWhitelisted === null) {
          let hostname = window.location.hostname;
          if (hostname === "localhost" && window.location.port !== "") {
            // Append port number if not default for localhost
            hostname += `:${window.location.port}`;
          }
          const response = await getData(id ? { event: id, slug, ...user } : { domain: hostname, slug, ...user }, url);
          setIsWhitelisted(response.data?.isWhitelisted);
          if (response.data?.response?.route === "event") {
            const tempTheme = {
              ...themeColors,
              theme: response.data.response.event.themeColor,
              themeBackground: response.data.response.event.themeColor,
              themeForeground: response.data.response.event.themeTextColor,
              secondaryColor: response.data.response.event.secondaryColor,
              secondaryTextColor: response.data.response.event.secondaryTextColor,
            };
            document.title = `${response.data.response?.event?.title}`;
            // Update meta tags for SEO
            const metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content = response.data.response?.event?.description;
            document.head.appendChild(metaDescription);

            !id && dispatch(changeThemeColor(tempTheme));
            const faviconUrl = process.env.REACT_APP_CDN + response.data.response?.event?.logo;
            if (response.data.response?.event?.logo) {
              const link = document.querySelector("link[rel~='icon']") || document.createElement("link");
              link.rel = "icon";
              link.href = faviconUrl;
              document.head.appendChild(link);
            }
            if (response.data.response?.event?.trackingCode) {
              const trackingCode = response.data.response?.event?.trackingCode;
              const script1 = document.createElement("script");
              script1.innerHTML = ` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${trackingCode}'); `;
              document.head.appendChild(script1);

              // Second script
              const script2 = document.createElement("script");
              script2.src = `https://www.googletagmanager.com/ns.html?id='${trackingCode}`;
              script2.async = true;
              document.head.appendChild(script2);
            }
          }
          serResponse(response);
          setShowLoader(false); // Hide loader after fetching data}
        }
      };
      if (!isLoaded) {
        fetchData();
      }
    }, [id, isWhitelisted, isLoaded, themeColors, dispatch, slug, user]);

    // const [isWhitelisted] = useState(response.data.isWhitelisted);
    // const [data] = useState(response.data.response);
    // const [config] = useState(response.data.configs ?? []);
    // const [additionalMenus] = useState(response.data.additionalMenusList ?? []);
    // const [theme] = useState(themeColors);
    return isWhitelisted === true ? (
      <React.Fragment>
        <WrappedComponent {...props} theme={themeColors} id={id ?? ""} slug={slug} data={response.data.response} isWhitelisted={response.data.isWhitelisted} config={response.data.configs ?? []} additionalMenus={response.data.additionalMenusList ?? []} setLoaderBox={setLoaderBox} setMessage={setMessageBox}></WrappedComponent>
        {showMessage && <Message message={message} closeMessage={closeMessage} setLoaderBox={setLoaderBox} showMessage={showMessage}></Message>}
        {showLoader && <Loader></Loader>}
      </React.Fragment>
    ) : isWhitelisted === null ? (
      <Loader></Loader>
    ) : (
      <NoData style={{ margin: "auto", display: "flex", height: "100vh", flexDirection: "column", gap: "10px" }} className="noshadow white-list">
        Page Not Found!
      </NoData>
    );
  };
};

export default withLayout;

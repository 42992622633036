import styled from "styled-components";
export const Header = styled.div`
  display: flex;
  padding: 10px;
  &.hd {
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 768px) {
    &.hd {
      display: none;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
export const Nav = styled.nav`
  padding-top: 0em;
  display: flex;
  flex-direction: column;
  color: gray;
  padding-bottom: 1em;
  overflow-y: auto;
  font-weight: bold;
  font-size: 14px;
  a.main,
  .open {
    text-decoration: none;
    color: gray;
    padding-left: 1em;
    height: 35px;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: all 0.2s;
    position: relative;
    margin: 5px 20px;
  }
  && {
    .down {
      a.main span,
      .open span {
        padding-right: 1em;
      }
    }
  }

  a.open {
    cursor: unset;
  }
  a.main.active,
  a.main:hover {
    background: #f6f8fa;
    border-radius: 10px;
    color: ${(props) => props.theme.theme};
    /* box-shadow: rgba(0, 0, 0, 0.16) -1px 0px 4px; */
    font-weight: normal;
    span {
      color: black;
    }
    opacity: 1;
  }
  a.main.active:after {
    content: "";
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-top: 1px solid black;
    border-right: 1px solid black;
    transition: all 0.15s ease-in-out 0s;
  }
  .down a.main.active:first-child,
  .down a.main:hover:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .down a.main.active:last-child,
  .down a.main:hover:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  a.main.active::after {
    /* content: ""; */
    /* width: 6px;
    color: white;
    height: 100%;
    background: rgb(82 103 96);
    margin-left: auto; */
  }
  a.main svg,
  .open svg {
    transition: all 0.2s;
    margin-right: 10px;
    width: 30px;
  }
  a.main.active svg,
  a.main:hover svg {
    width: 30px;
    transform: scale(1.1);
  }
  @media (max-width: 768px) {
    box-shadow: 1px -1px 11px 0 rgba(0, 0, 0, 0.07);
    position: fixed;
    bottom: 0;
    z-index: 1001;
    background: white;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    padding: 0;
    overflow: auto;
    .menu-item {
      padding: 0;
      margin: 0;
      height: 49px;
      width: 20%;
    }
    a.main svg,
    .open svg {
      transition: all 0.2s;
      margin-right: 0px;
      width: 30px;
    }
    a.main,
    .open {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      padding: 0;
      width: 60px;
      overflow: hidden;

      svg {
      }
      span {
        padding: 0;
        overflow: hidden;
        font-size: 10px;
        white-space: nowrap;
        text-align: center;
        max-width: 95%;
        text-overflow: ellipsis;
      }
    }
  }
`;
export const SubMenuHead = styled.div`
  padding: 10px 1.5em;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  display: flex;
  @media (max-width: 768px) {
    padding: 10px 1em;
  }
`;
export const SubMenuOpen = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 97px;
  width: calc(15em - 60px);
  box-shadow: rgb(237, 237, 237) 6px 0px 11px 3px;
  height: 100%;
  a {
    padding: 10px 2em 10px 1.5em;
    text-decoration: none;
    display: flex;
    gap: 10px;
    text-decoration: none;
    color: ${(props) => props.theme.secForeground};
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 14px;
    transition: all 0.2s;
    position: relative;
    margin: 5px 20px;
    background: #f6f8fa;
    border-radius: 10px;
    color: ${(props) => props.theme.theme};
  }
  a:hover,
  a.active {
    background: linear-gradient(102deg, rgb(2, 0, 36) 0%, rgb(232 232 232) 0%, rgb(255, 255, 255) 83%);
    color: ${(props) => props.theme.theme};
    box-shadow: rgba(0, 0, 0, 0.16) -1px 0px 4px;
    font-weight: bold;
    opacity: 1;
  }
  a.active:after {
    border: 0px solid ${(props) => props.theme.themeColor};
    content: "";
    display: block;
    height: 100%;
    width: 3px;
    right: 0;
    border-radius: 3px;
    margin-left: calc(100% - 3px);
    background: ${(props) => props.theme.theme};
    transition: all 0.15s ease-in-out 0s;
    position: absolute;
    @media (max-width: 768px) {
      height: 3px;
      width: 100%;
      bottom: 0;
    }
  }
  @media (max-width: 768px) {
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: initial;
    padding: 0;
    box-shadow: none;
    width: 100%;
    overflow: auto;
    gap: 15px;
    height: 43px;
    padding-right: 20px;
    :before {
      content: "";
      display: block;
      border-radius: 3px;
      background: #dbdbdb;
      transition: all 0.15s ease-in-out 0s;
      height: 1px;
      margin-left: 0;
      position: absolute;
      margin-top: 1px;
      left: 10px;
      right: 10px;
    }
    a {
      width: auto;
      white-space: nowrap;
      padding: 10px 00em;
      color: #9797bc;
      height: 43px;
    }
    a:hover,
    a.active {
      color: ${(props) => props.theme.theme};
      font-weight: bold;
      opacity: 1;
      background: transparent;
      box-shadow: none;
    }
  }
`;
export const SubMenu = styled.nav`
  margin-left: 1em;
  margin-right: 1em;
  padding-left: 0em;
  border: 1px solid #f2e5e5;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 2px;
  &.close {
    display: none;
  }
  a {
    border-bottom: 1px solid rgb(241 241 241);
  }
  a:last-child {
    border-bottom: 0;
  }
`;
export const MenuGroup = styled.div`
  cursor: pointer;
  transition: all 0.2s;
  svg:last-child {
    margin-left: auto;
  }
  &.active svg:last-child {
    transform: rotate(180deg) scale(1.1);
    font-weight: bold;
    opacity: 1;
  }
`;
export const MobileSubMenu = styled.div`
  z-index: 100;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
  background: white;

  flex-direction: initial;
  padding: 0px;
  overflow: auto;
  flex-direction: column;
  box-shadow: 0px 2px 7px 0 rgba(0, 0, 0, 0.07);
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import { getData, postData } from "../../../../backend/api";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const RegistrationFormData = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Gallery - EventHex Portal`;
  }, []);
  const [data] = useState(props.openData.data);
  const [attributes, setSubAttributes] = useState(null);
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    const getTickets = async () => {
      setLoading(true);
      const response = await getData({ ticket: data._id }, "ticket-form-data");
      if (response.status === 200) {
        const base = [
          {
            type: "text",
            placeholder: "_id",
            name: "_id",
            validation: "",
            default: "",
            label: "_id",
            minimum: 5,
            maximum: 40,
            required: true,
            view: false,
            tag: false,
            export: true,
          },
          {
            type: "text",
            placeholder: "User Name",
            name: "fullName",
            validation: "",
            collection: "authentication",
            showItem: "fullName",
            default: "",
            label: "User Name",
            minimum: 0,
            maximum: 16,
            required: true,
            view: true,
            tag: true,
          },

          {
            type: "text",
            placeholder: "Last Name",
            name: "authenticationId",
            validation: "",
            default: "",
            label: "WhatsApp Number",
            collection: "authentication",
            showItem: "authenticationId",
            minimum: 5,
            maximum: 40,
            required: true,
            view: true,
            tag: false,
            export: true,
          },
          {
            type: "text",
            placeholder: "Email ID",
            name: "emailId",
            validation: "",
            default: "",
            label: "Email Id",
            collection: "authentication",
            showItem: "formData",
            showSubItem: "emailId",
            minimum: 5,
            maximum: 40,
            required: true,
            view: true,
            tag: true,
            export: true,
          },
          {
            type: "text",
            placeholder: "Token",
            name: "token",
            validation: "",
            default: "",
            label: "Token",
            collection: "",
            showItem: "",
            required: true,
            view: true,
            tag: true,
            export: true,
          },
        ];
        setSubAttributes([
          ...base,
          ...response.data.response.map((item) => {
            let newItem = {
              ...item,
              collection: "formData",
            };
            if (item.conditionEnabled) {
              newItem.condition = {
                item: item.conditionWhenField,
                if: item.conditionCheckMatch.includes(",") ? item.conditionCheckMatch.split(",") : [item.conditionCheckMatch],
                then: item.conditionIfMatch === "enabled" ? "enabled" : "disabled",
                else: item.conditionIfMatch === "enabled" ? "disabled" : "enabled",
              };
            }

            return newItem;
          }),
          {
            type: "datetime",
            placeholder: "Registered On",
            name: "createdAt",
            validation: "",
            default: "",
            label: "Registered On",
            minimum: 0,
            maximum: 16,
            required: true,
            view: true,
            tag: true,
          },
          {
            type: "select",
            placeholder: "Approval",
            name: "approve",
            validation: "",
            default: "",
            collection: "",
            label: "Approval",
            required: false,
            selectApi: [
              { id: true, value: "Approved" },
              { id: false, value: "Pending" },
            ],
            apiType: "JSON",
            view: true,
            tag: true,
            add: false,
            filter: true,
            export: true,
          },
        ]);
      }
    };
    if (!attributes && !loading) {
      getTickets();
    }
  }, [data, attributes, loading]);

  const getApproved = (id, refreshView, slNo) => {
    props.setLoaderBox(true);
    postData({ id }, "authentication/approve")
      .then((response) => {
        props.setLoaderBox(false);
        if (response.data) {
          props.setMessage({ type: 1, content: response.data.message, icon: "success" });
          refreshView(false, slNo, { approve: true });
        } else {
          // Handle the case where response.data is undefined
          console.error("Response data is undefined.");
        }
      })
      .catch((error) => {
        props.setLoaderBox(false);
        // Handle any errors that occur during the API request
        console.error("API request error:", error);
      });
  };

  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView, slNo) => {
        getApproved(data._id, refreshView, slNo);
      },
      itemTitle: {
        name: "user",
        type: "text",
        collection: "",
      },
      condition: {
        item: "approve",
        if: "false",
        then: true,
        else: false,
      },
      icon: "next",
      title: "Approve",
      params: {
        api: ``,
        parentReference: "",
        itemTitle: {
          name: "user",
          type: "text",
          collection: "",
        },
        shortName: "Approve",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
      },
    },
  ]);
  // console.log(actions)
  return (
    attributes && (
      <Container className="noshadow">
        <ListTable
          actions={actions}
          api={`ticket-registration`}
          itemTitle={{
            name: "fullName",
            type: "text",
            collection: "authentication",
          }}
          shortName={`Ticket Registration`}
          formMode={`single`}
          preFilter={{ ticket: data._id }}
          delPrivilege={false}
          addPrivilege={false}
          updatePrivilege={false}
          exportPrivilege={true}
          // viewMode={"table"}
          {...props}
          attributes={attributes}
        ></ListTable>
      </Container>
    )
  );
};
// exporting the page with parent container layout..
export default Layout(RegistrationFormData);

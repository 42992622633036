import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import registrationFormData from "../registrationFormData";
// import moment from "moment";
const TicketAdmin = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Event - EventHex Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Attendances",
      name: "attendanceCount",
      validation: "",
      default: 0,
      label: "Attendances",
      tag: true,
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Short Description",
      name: "shortDescription",
      validation: "",
      default: "",
      label: "Short Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Booking Count",
      name: "bookingCount",
      validation: "",
      default: null,
      tag: true,
      label: "Booking Count",
      required: false,
      view: true,
      add: false,
      update: false,
    },

    {
      type: "datetime",
      placeholder: "Start Date",
      name: "startDate",
      validation: "",
      default: "",
      tag: true,
      label: "Start Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "End Date",
      name: "endDate",
      validation: "",
      default: "",
      tag: true,
      label: "End Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Registration Start Date",
      name: "registrationStartDate",
      validation: "",
      default: "",
      tag: true,
      label: "Start Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Registration End Date",
      name: "registrationEndDate",
      validation: "",
      default: "",
      tag: true,
      label: "End Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Duration",
      name: "duration",
      validation: "",
      default: "",
      tag: true,
      label: "Duration",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Venue",
      name: "venue",
      validation: "",
      default: "",
      tag: true,
      label: "Venue",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Slug",
      name: "slug",
      validation: "slug",
      default: "",
      tag: true,
      label: "slug",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Tag",
      name: "tag",
      validation: "",
      default: "",
      label: "Tag",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Thumbnail Image",
      name: "thumbnail",
      validation: "",
      default: "",
      tag: true,
      label: "Thumbnail Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Banner",
      name: "banner",
      validation: "",
      default: "",
      tag: true,
      label: "Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Category",
      name: "category",
      validation: "",
      default: "",
      tag: true,
      label: "Category",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Content",
      name: "content",
      validation: "",
      default: "",
      tag: false,
      label: "Content",
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Status",
      name: "status",
      validation: "",
      default: null,
      tag: true,
      label: "Status",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Direct Registration",
      name: "enableDirectRegistration",
      validation: "",
      default: false,
      tag: true,
      label: "Enable Direct Registration",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Override `Name Field` Label",
      name: "overrideYourNameField",
      validation: "",
      condition: {
        item: "enableDirectRegistration",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Override `Name Field` Label",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Registration",
      name: "enableRegistration",
      validation: "",
      default: true,
      tag: true,
      label: "Enable Registration",
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "checkbox",
      placeholder: "ID Card Required",
      name: "idCardRequired",
      validation: "",
      default: null,
      tag: true,
      label: "ID Card Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Registration Required",
      name: "registrationRequired",
      validation: "",
      default: null,
      tag: true,
      label: "Registration Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Registration Notification Required",
      name: "registrationNotificationRequired",
      validation: "",
      default: null,
      tag: true,
      label: "Registration Notification Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Detail Page Required",
      name: "detailPageRequired",
      validation: "",
      default: null,
      tag: true,
      label: "Detail Page Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Registration Setting",
      name: "RegistrationSetting",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Maximum Registrations",
      name: "maximumRegistrations",
      validation: "",
      default: 0,
      label: "Maximum Registrations",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Registration Expiry Date",
      name: "registrationExpiryDate",
      validation: "",
      default: "",
      label: "Registration Expiry Date",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Multiple Entry Allowed",
      name: "isMultipleEntry",
      validation: "",
      default: null,
      tag: true,
      label: "Multiple Entry Allowed",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Multiple Entri Limit",
      name: "multipleEntriLimit",
      validation: "",
      default: 1,
      label: "Multiple Entri Limit",
      required: true,
      view: true,
      add: true,
      update: true,
      condition: {
        item: "isMultipleEntry",
        if: true,
        then: "enabled",
        else: "disabled",
      },
    },
    {
      type: "title",
      title: "Ticketing",
      name: "Ticketing",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Group Ticketing",
      name: "groupTicketing",
      validation: "",
      default: null,
      tag: true,
      label: "Group Ticketing",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Paid",
      name: "freeOrPaid",
      validation: "",
      default: "",
      tag: true,
      label: "Paid",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Cost",
      name: "cost",
      condition: {
        item: "freeOrPaid",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Cost",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Tax",
      name: "tax",
      condition: {
        item: "freeOrPaid",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Tax",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Badge & Certificate",
      name: "Badge & Certificate",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Badge",
      name: "idCard",
      validation: "",
      default: null,
      tag: true,
      label: "Badge",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Badge Width",
      name: "idCardWidth",
      condition: {
        item: "idCard",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Badge Width",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Badge Height",
      name: "idCardHeight",
      condition: {
        item: "idCard",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Badge Height",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Certificate",
      name: "certificate",
      validation: "",
      default: null,
      tag: true,
      label: "Certificate",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Certificate Width",
      name: "certificateWidth",
      condition: {
        item: "certificate",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Certificate Width",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Certificate Height",
      name: "certificateHeight",
      condition: {
        item: "certificate",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Certificate Height",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "On Successfull Message",
      name: "OnSuccessfullMessage",
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "On Successfull Message",
      name: "onsuccessfullMessage",
      validation: "",
      default: "",
      label: "On Successfull Message",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Email Template",
      name: "emailTemplate",
      validation: "",
      default: "",
      label: "Email Template",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Whatsapp Template",
      name: "whatsappTemplate",
      validation: "",
      default: "",
      label: "Whatsapp Template",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [attendance] = useState([
    {
      type: "text",
      placeholder: "User",
      name: "fullName",
      collection: "user",
      showItem: "fullName",
      validation: "",
      default: "",
      tag: false,
      label: "User",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "text",
      placeholder: "Mobile Number",
      name: "authenticationId",
      collection: "user",
      showItem: "authenticationId",
      validation: "",
      default: "",
      tag: true,
      label: "Mobile Number",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "checkbox",
      placeholder: "Is Present",
      name: "isVerified",
      collection: "user",
      showItem: "isVerified",
      validation: "",
      default: null,
      tag: true,
      label: "Is Present",
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "datetime",
      placeholder: "Date",
      name: "createdAt",
      validation: "",
      default: "",
      label: "Date",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "attendance",
      itemTitle: "Attendance",
      title: "Attendance",
      attributes: attendance,
      params: {
        api: `attendance`,
        parentReference: "ticket",
        icon: "user",
        itemTitle: {
          name: "fullName",
          type: "text",
          collection: "user",
        },
        shortName: "Attendance",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        exportPrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
    {
      element: "button",
      type: "custom",
      id: "ticket-registration",
      icon: "ticket-registration",
      title: "Ticket Registration",
      content: registrationFormData,
    },
  ]);
  return (
    <Container className="noshadow">
      <ListTable
        popupMode="full-page"
        // popupMenu={"vertical-menu"}
        actions={actions}
        api={`ticket/ticket-admin`}
        itemTitle={{ name: "title", type: "text", collection: "" }}
        shortName={`Ticket Admin`}
        formMode={`double`}
        {...props}
        attributes={attributes}
      ></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(TicketAdmin);
